<template>
  <div>
    <v-container style="height: 80vh">
      <v-row justify="center" align="center" style="height: 100%">
        <v-col cols="12" sm="6" md="4">
          <v-card flat style="background: transparent !important; width: 100%">
            <v-card-text style="width: 100%; margin-top: 100px">
              <v-row justify="center">
                <v-col cols="2">
                  <div class="cube-loader">
                    <div class="cube-top"></div>
                    <div class="cube-wrapper">
                      <span class="cube-span" style="--i: 0">
                        <div
                          style="text-align: center"
                          class="taco-container text-center"
                        >
                          <v-avatar size="60">
                            <v-img
                              src="https://cdn.loglive.io/integrations/Xero.png"
                              contain
                            ></v-img>
                          </v-avatar>
                        </div>
                      </span>
                      <span class="cube-span" style="--i: 1">
                        <div
                          style="text-align: center"
                          class="taco-container text-center"
                        >
                          <v-avatar size="60">
                            <v-img
                              src="https://cdn.loglive.io/integrations/Xero.png"
                              contain
                            ></v-img>
                          </v-avatar>
                        </div>
                      </span>
                      <span class="cube-span" style="--i: 2">
                        <div
                          style="text-align: center"
                          class="taco-container text-center"
                        >
                          <v-avatar size="60">
                            <v-img
                              src="https://cdn.loglive.io/integrations/Xero.png"
                              contain
                            ></v-img>
                          </v-avatar>
                        </div>
                      </span>
                      <span class="cube-span" style="--i: 3">
                        <div
                          style="text-align: center; height: 100%"
                          class="taco-container text-center"
                        >
                          <v-avatar size="60">
                            <v-img
                              src="https://cdn.loglive.io/integrations/Xero.png"
                              contain
                            ></v-img>
                          </v-avatar>
                        </div>
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="text-center" style="margin-top: 100px">
                  <h2>
                    <v-icon
                      class="mr-2"
                      v-if="$route.query.status == 'success'"
                      color="success"
                      large
                      >check_circle_outline</v-icon
                    >
                    <v-icon
                      class="mr-2"
                      v-else-if="$route.query.status == 'error'"
                      color="red"
                      large
                      >error</v-icon
                    >
                    {{ $route.query.message }}
                  </h2>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
    created(){
        // setTimeout(() => {
        //     this.$router.push({ path: '/landing' });
        // }, 5000);
    }
};
</script>
<style>
.cube-loader {
  /* position: relative; */
  /* u can choose any size */
  width: 75px;
  height: 75px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotateX(-30deg) rotateY(0);
  }

  100% {
    transform: rotateX(-30deg) rotateY(360deg);
  }
}

.cube-loader .cube-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  /* top: 0;
  left: 0; */
  transform-style: preserve-3d;
}

.cube-loader .cube-wrapper .cube-span {
  position: absolute;
  width: 100%;
  height: 100%;
  /* top: 0;
  left: 0; */
  /* width 75px / 2 = 37.5px */
  transform: rotateY(calc(90deg * var(--i))) translateZ(37.5px);
  background: hsla(0, 100%, 95%, 1);
  background: linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -moz-linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#ffe3e3", endColorstr="#ffd5b2", GradientType=1 );
}

.cube-top {
  position: absolute;
  width: 75px;
  height: 75px;
  background: rgb(135, 135, 135);
  background: linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -moz-linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#ffe3e3", endColorstr="#ffd5b2", GradientType=1 );
  /* width 75px / 2 = 37.5px */
  transform: rotateX(90deg) translateZ(37.5px);
  transform-style: preserve-3d;
}

.cube-top::before {
  content: "";
  position: absolute;
  /* u can choose any size */
  width: 75px;
  height: 75px;
  background: rgb(135, 135, 135);
  background: linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -moz-linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(201, 201, 201) 10%,
    rgb(158, 158, 158) 99%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#ffe3e3", endColorstr="#ffd5b2", GradientType=1 );
  background: linear-gradient(
    90deg,
    rgb(201, 201, 201) 1%,
    rgb(158, 158, 158) 99%
  );
  background: -moz-linear-gradient(
    90deg,
    rgb(201, 201, 201) 10%,
    rgb(158, 158, 158) 99%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(201, 201, 201) 10%,
    rgb(158, 158, 158) 99%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#ffe3e3", endColorstr="#ffd5b2", GradientType=1 );
  transform: translateZ(-90px);
  filter: blur(10px);
  box-shadow: 0 0 10px #323232, 0 0 20px var(--v-primary-base) 50%,
    0 0 30px #323232, 0 0 40px var(--v-primary-base) 19.6%;
}
</style>